<template>
  <div class="flix-bg-info" style="padding: 10px" v-if="between">
    <flixIcon id="info-sign" /> {{ $tc('message.appointments', 1) }} {{ $tc('message.timeRange', 1) }}: <b>{{ between }}</b>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    date: Object
  },
  data () {
    return {
      between: false
    }
  },
  computed: {

  },
  mounted () {
    this.between = this.$timeBetween(this.$createDate(this.date.end), this.$createDate(this.date.begin))
  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
</style>
